import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = ["linkedinUrl"]
  connect() {
    const linkedinUrlField = this.linkedinUrlTarget
    if (linkedinUrlField.value.trim() === "") {
      linkedinUrlField.value = "linkedin.com/"
    }
  }

  fillLinkedinUrl() {
  }
}
